import React from "react";
import { graphql } from 'gatsby'
import ReactMarkdown from "react-markdown";
import { createHubSpotForm, resetWebflow } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsLandingPage3: {
      coverPhoto,
      hubspotFormId,
      mainContent,
      title,
      seoMetaTags,
      notificationPage
    },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  resetWebflow("5bc1e8b55b2ebf49a651c6e0");
  createHubSpotForm({
    formId: hubspotFormId,
    css: "",
    targetId: "#LandingPage3Form"
  });
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      {coverPhoto && (
        <div class="main-header landing-page">
          <img style={{maxHeight: "350px" }} src={coverPhoto.url} alt={coverPhoto.alt} />
        </div>
      )}
      <div class="white-section only-bottom">
        <div class="container grid">
          <div class="_2cols-box-extended">
            <div class="md-spacing" />
            <div class="post-container">
              <ReactMarkdown source={mainContent} escapeHtml={false} />
            </div>
          </div>
          <div class="_2cols-box-extended">
            <div class="md-spacing" />
            <div id="LandingPage3Form" class="w-embed w-script" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query LandingPage3Query($slug: String!) {
    datoCmsLandingPage3(slug: { eq: $slug }) {
      title
      mainContent
      hubspotFormId
      coverPhoto {
        url
        alt
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      notificationPage {
        id
        page
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
